//React
import React from 'react';
//Components
import { Screen } from '../../components';
import { history } from '../../utils';
//Styles
import './styles.scss';
class Tnc extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toCall: true,
			toUpdate: true,
		};
	}

	render() {
		const { state, props } = this;
		return (
			<Screen screenClass="tnc-screen animated zoomIn">
				<div className="content">
					<p className="fs-36 font-heavy">TERMS AND CONDITIONS</p>
					<p className="fs-18 font-light mr-v-20">
						Resilient Innovations Private Limited (<span className="font-medium">“Company”</span>{' '}
						<span className="font-medium">“we”</span> <span className="font-medium">“us”</span>) is a private limited
						company engaged in the business of facilitating a suite of services to customers through its digital
						platform, on software as a service model, and providing other value-added services to such customers.
					</p>
					<p className="fs-18 font-light mr-v-20">
						In connection therewith, subject to and on the terms and conditions set out herein, the Company has agreed
						to provide the 12% Club Services (defined hereinafter) to any user of of the Platform (
						<span className="font-medium">“you”</span> or the <span className="font-medium">“user”</span>) and you,
						hereby, agree to avail the 12% Club Services in accordance with these terms of use (
						<span className="font-medium">“Terms”</span> or <span className="font-medium">“Terms of Use”</span>).
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please note that by use of the 12% Club application or the{' '}
						<span
							className="underline font-medium"
							onClick={() => {
								window.open('https://twelve.club', '_blank');
							}}
						>
							https://twelve.club
						</span>{' '}
						website (collectively referred to as “Platform”) and any services provided by the Company, you also agree to
						be bound by the additional terms and conditions and policies referenced herein and/or available by
						hyperlink, including without limitation the Company’s Privacy Policy (available at{' '}
						<span
							className="underline font-medium"
							onClick={() => {
								window.open('https://twelve.club/#/privacy-policy', '_blank');
							}}
						>
							https://twelve.club/#/privacy-policy
						</span>
						), other policies of the Company.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please read these Terms carefully before accessing or using Platform and any services provided by the
						Company. By accessing or using any part of Platform or any services provided by the Company, you agree to be
						bound by these Terms. If you do not agree to all the Terms, then you may not access the Platform or the use
						any products, application or services provided by the Company. These Terms shall be read in conjunction with
						any specific terms of use or conditions for the use of the 12% Club Services.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You can review the most current version of the Terms at any time on this page. The Company reserves the
						right to update, change or replace any part of these Terms by posting updates and/or changes to Platform It
						is your responsibility to check this page periodically for changes. Following the posting of any changes,
						your continued use of or access to the Platform or the use of the product or services from the Company,
						constitutes acceptance of such revised Terms of Use.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please note that the Company is merely a facilitator of products which are provided by the Partners and the
						Company does not directly provide any financial services/offer buying or selling gold to the Users. The
						Company is not an organization registered with the Reserve Bank of India and does not hold any license to
						engage in any activities relating to lending or borrowing and is not a financial institution regulated under
						the Banking Regulation Act, 1949, the Reserve Bank of India Act, 1934 or any other laws for the time being
						in force in India. The Company is also not a deposit taking company or a chit fund or a company offering any
						investment schemes as per the rules and regulations for the time being in force in India.
					</p>
					<p className="fs-18 font-heavy mr-t-50">DEFINITIONS</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“12% Club Services”</span> means the services provided by the 12% Club from
						time to time;
					</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“Applicable Laws”</span> means all applicable (i) statutes, enactments, acts
						of legislature or parliament, laws, bye-laws, ordinances, rules, regulations, listing agreements,
						notifications, guidelines or policies of any applicable jurisdiction; (ii) administrative interpretation,
						writ, injunction, directions, directives, judgment, arbitral award, decree, orders or consents of, or
						agreements with, any Governmental Authority or a recognized stock exchange; and (iii) international tax
						treaties, as may be in force from time to time;
					</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“Governmental Authority”</span> means any Indian or non-Indian court of
						competence, arbitral tribunal, legislative, executive or administrative agency or commission or other
						governmental or regulatory agency or authority or any securities exchange;
					</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“Intellectual Property Rights”</span> means:
					</p>
					<p className="fs-18 font-light mr-v-20">
						patents, trademarks, trade names, service marks, service names, logos, internet domain names, corporate
						names, rights in designs, copyright (including right in computer software) and moral rights, database
						rights, semiconductor topography rights, utility models, formulae, processes, trade secrets, proprietary
						information, rights in knowhow and other intellectual property rights, in each case, whether registered or
						not and including applications for registration, and all rights or forms of protection having equivalent or
						similar effect anywhere in the world;
					</p>
					<p className="fs-18 font-light mr-v-20">
						rights under licenses, consents, order or statutes or otherwise in relation to a right under paragraph (a);
					</p>
					<p className="fs-18 font-light mr-v-20">
						rights of the same or similar effect or nature as or to those under paragraph (a) and (b) which now or in
						the future may subsist;
					</p>
					<p className="fs-18 font-light mr-v-20">
						the right to sue for past infringements or any of the foregoing rights.
					</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“Partner”</span> means RBI Regulated Peer to Peer NBFCs, Banks, Digital Gold
						India Private Limited and/or any third parties who have entered into an agreement with the Company in
						connection with the provision of the 12% Club Services; and
					</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">“Person”</span> shall mean any natural person, limited or unlimited liability
						company, corporation, partnership (whether limited or unlimited), proprietorship, Hindu undivided family,
						trust, union, association, government or any agency or political subdivision thereof or any other entity
						that may be treated as a person under applicable law.
					</p>
					<p className="fs-18 font-heavy mr-t-50">ELIGIBILITY TO USE</p>
					<p className="fs-18 font-light mr-v-20">
						You expressly acknowledge and agree that: (i) you are a natural person; (ii) you are capable of entering and
						authorized to enter into a legally binding agreement; (iii) all information you submit is accurate and
						truthful; (v) you will maintain the accuracy of such information; (vi) you are not barred or otherwise
						legally prohibited from accessing or using 12% Club Services under Applicable Laws; (vii) you expressly
						acknowledge and agree that use of the 12% Club Services is permitted only for those who are physically
						located in India at the time of registration.
					</p>
					<p className="fs-18 font-light mr-v-20">
						To avail the 12% Club Services, you should be an Indian citizen and resident with an active Indian mobile
						number and bank account.
					</p>
					<p className="fs-18 font-light mr-v-20">
						In order to avail of the services from the Company and/or the Partners, you will need to provide details
						which includes accurate personal information. You will be required to submit certain documentation including
						but not limited to the User’s PAN card number, Aadhaar card number, phone number and other details (
						<span className="font-medium">“Information”</span>). You consent to the collection and storage of the
						Information and disclosure to the Partners and other persons, as deemed necessary for the purposes mentioned
						herein or in connection with the 12% Club Services. By accepting these Terms of Use, you further grant the
						Company the right to share your personal information with the Company’s partners, affiliates, employees and
						agents for administrative purposes (including the Partners). The User represents and warrants that the
						information provided by the User to the Company is true and correct in all respects.
					</p>
					<p className="fs-18 font-heavy mr-t-50">OFFERS</p>
					<p className="fs-18 font-light mr-v-20">
						Company may run limited time offers in connection with any products , either directly or in partnership with
						third parties. Company reserves the right to introduce, withdraw, or change the terms of any such offer at
						any time without prior notice. Any cashback that may be offered on Company or through other media channels
						is indicative and may be less than the advertised amount
					</p>
					{/* <p className="fs-18 font-heavy mr-t-50">FEES AND CHARGES</p>
          <p className="fs-18 font-light mr-v-20">
            In connection with the use of the 12% Club Services, the Company
            shall specify the fees and charges payable to the Company for the
            provision of the relevant 12% Club Services. The charges and fees is
            exclusive of any applicable taxes, all of which shall be the
            liability of and charged to you.
          </p>
          <p className="fs-18 font-light mr-v-20">
            Without prejudice to the above, the Partners may charge separate
            fees and charges in connection with the services or facilities
            provided to you by the Partners, as specified to you.
          </p> */}
					<p className="fs-18 font-heavy mr-t-50">REPRESENTATIONS, WARRANTIES AND COVENANTS</p>
					<p className="fs-18 font-light mr-v-20">You hereby represent, warrant and covenant with the Company that:</p>
					<p className="fs-18 font-light mr-v-20">
						all information provided by you to the Company and/or the Partners is and shall be maintained as true,
						correct and accurate in all respects;
					</p>
					<p className="fs-18 font-light mr-v-20">
						you are not barred or otherwise prohibited from accessing or using 12% Club Services or the services /
						products provided by the Partners in any manner;
					</p>
					<p className="fs-18 font-light mr-v-20">
						you shall not impersonate any person or entity, falsely claim or otherwise misrepresent an affiliation with
						any person or entity, or access the accounts of others without permission, forge another person’s digital
						signatures or perform any other fraudulent activity;
					</p>
					<p className="fs-18 font-light mr-v-20">
						you shall promptly provide all information and assistance as may be required by the Company or the Partners
						from time to time in dealing with requests or queries of any Governmental Authority.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You agree to provide KYC and similar relevant details & documents to Company and the Partners, in electronic
						and physical form, as may be required by Company. Also, you hereby authorise the Company to use your KYC and
						details for all such services that the Company may offer and you may avail, by itself or through third
						parties, from time to time.
					</p>
					{/* <p className="fs-18 font-light mr-v-20">
            You authorise the Company to read and access messages on the device
            utilising the 12% Club Services for retrieval and use for the
            enhancement of 12% Club Services.
          </p> */}
					<p className="fs-18 font-light mr-v-20">
						You shall not share the username and password with other persons and acknowledge that such sharing shall
						cause irreparable harm to you and the Company and that you shall be liable to indemnify the Company and the
						Partners for any loss or damage suffered as a result of any unauthorised use in connection hereto.
					</p>
					<p className="fs-18 font-light mr-v-20">
						It is agreed that the Company and the Partners shall be entitled to rely upon all electronic communications,
						orders or messages sent to Company and the Partners. The Company and the Partners shall not be obligated to
						verify or make further inquiry into the identity of the sender, or integrity of any communications. The
						Company and the Partners shall not be responsible for any losses sustained through the use of stolen or
						hacked devices or fraudulent electronic transactions.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Without limiting the foregoing, you agree that you shall not use the 12% Club Services or the
						products/services of the Partners to undertake any of the following actions or to display, upload, modify,
						publish, distribute, disseminate, transmit, update or share any information that:
					</p>
					<p className="fs-18 font-light mr-v-20">
						is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libellous,
						invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or
						encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever;
					</p>
					<p className="fs-18 font-light mr-v-20">
						infringes any patent, trademark, copyright or other proprietary rights;
					</p>
					<p className="fs-18 font-light mr-v-20">
						contains viruses, corrupted files, or any other similar software or programs that is designed to interrupt,
						destroy or that may limit the functionality of any computer source or that may damage or adversely affect
						the operation of another person's computer, its web-sites, any software or hardware, or telecommunications
						equipment;
					</p>
					<p className="fs-18 font-light mr-v-20">
						advertises or offers to sell any goods or services for any commercial purpose;
					</p>
					<p className="fs-18 font-light mr-v-20">
						is in the nature of promotional services, products, surveys, contests, pyramid schemes, spam, unsolicited
						advertising or promotional materials, or chain letters;
					</p>
					<p className="fs-18 font-light mr-v-20">
						falsifies or deletes any author attributions, legal or other proper notices or proprietary designations or
						labels of the origin or the source of software or other material;
					</p>
					<p className="fs-18 font-light mr-v-20">violates any law for the time being in force;</p>
					<p className="fs-18 font-light mr-v-20">belongs to another Person to which you do not have any right to;</p>
					<p className="fs-18 font-light mr-v-20">
						interferes with or disrupts the 12% Club Services or the services/products of the Partners or their
						websites, servers, or networks;
					</p>
					<p className="fs-18 font-light mr-v-20">impersonate any other person;</p>
					<p className="fs-18 font-light mr-v-20">harms minors in any way;</p>
					<p className="fs-18 font-light mr-v-20">
						forges headers or manipulates identifiers or other data in order to disguise the origin of any content
						transmitted through its websites or to manipulate the your presence on its websites;
					</p>
					<p className="fs-18 font-light mr-v-20">engages in any illegal activities;</p>
					<p className="fs-18 font-light mr-v-20">
						defames the Company or the Partners, and their employees, officers, directors, agents, partners and service
						providers, including without limitation, by posting any defamatory content on any social media; and
					</p>
					<p className="fs-18 font-light mr-v-20">
						threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign
						states, or public order, or causes incitement to the commission of any cognizable offence, or prevents the
						investigation of any offence or insults any other nation.
					</p>
					<p className="fs-18 font-light mr-v-20">You shall not attempt to or engage in any activity that may:</p>
					<p className="fs-18 font-light mr-v-20">
						reverse engineer, decompile or otherwise extract the source code related to the 12% Club Services or the
						services/products of the Partners or their websites, servers, or networks or any part thereof,;
					</p>
					<p className="fs-18 font-light mr-v-20">
						use any robot, spider, retrieval platform or other similar devices;
					</p>
					<p className="fs-18 font-light mr-v-20">
						collect information about other users for any illegal or unlawful purpose;
					</p>
					<p className="fs-18 font-light mr-v-20">
						create any user accounts by automated means or under false or fraudulent pretenses;
					</p>
					<p className="fs-18 font-light mr-v-20">
						transmit any viruses, worms, defects, trojan horses, or any items of a destructive nature;
					</p>
					<p className="fs-18 font-light mr-v-20">
						use the 12% Club Services or the services/products of the Partners or their websites, servers, or networks
						in any manner that could damage, disable, overburden, or impair, or undertake any action which is harmful or
						potentially harmful to, any of the servers, networks, computer systems or resources connected to any of the
						servers connected, directly or indirectly to the 12% Club Services or the services/products of the Partners
						or their websites, servers, or networks, or interfere with any other third party Person's use and enjoyment
						of the 12% Club Services or the services/products of the Partners or their websites, servers, or networks;
						and
					</p>
					<p className="fs-18 font-light mr-v-20">
						carry out any denial of service (DoS, DDoS) or any other harmful attacks on the 12% Club Services or the
						services/products of the Partners or their websites, servers, or networks or; disrupt or place unreasonable
						burdens or excessive loads on, or interfere with or attempt to make, or attempt any unauthorized access to
						the 12% Club Services or the services/products of the Partners or their websites, servers, or networks.
					</p>
					<p className="fs-18 font-heavy mr-t-50">SUSPENSION</p>
					<p className="fs-18 font-light mr-v-20">
						The Company reserves the right to suspend/discontinue the 12% Club Services or the services/products of the
						Partners to you and/or to sever its relationship with you, at any time, for any cause, including, but not
						limited, to the following:
					</p>
					<p className="fs-18 font-light mr-v-20">
						For any suspected violation of the rules, regulations, orders, directions, notifications, laws, statutes,
						from time to time or for any violation of the terms and conditions mentioned in these Terms of Use.
					</p>
					<p className="fs-18 font-light mr-v-20">
						For any suspected discrepancy in the particular(s), documentation or information provided by you;
					</p>
					<p className="fs-18 font-light mr-v-20">
						To combat potential fraud, sabotage, willful destruction, threat to national security, for any other force
						majeure reasons etc.;
					</p>
					<p className="fs-18 font-light mr-v-20">
						If the same is due to technical failure, modification, upgradation, variation, relocation, repair, and/or
						maintenance due to any emergency or for any technical reasons;
					</p>
					<p className="fs-18 font-light mr-v-20">
						If the same is due to any transmission deficiencies caused by topographical and geographical
						constraints/limitations;
					</p>
					<p className="fs-18 font-light mr-v-20">
						If you do not have the control over your login details and password or your account is compromised in any
						other manner;
					</p>
					<p className="fs-18 font-light mr-v-20">
						If you indulge in any abusive or aggressive or threatening behavior or any act or any intimidation or
						harassment of any kind (be it verbal or physical or written) against any employee / representative / agent
						of the Company or the Partners or any other user; and
					</p>
					<p className="fs-18 font-light mr-v-20">
						If the Company believes, in its reasonable opinion, that cessation/ suspension is necessary.
					</p>
					<p className="fs-18 font-heavy mr-t-50">WARRANTIES</p>
					<p className="fs-18 font-light mr-v-20">
						No warranty of any kind, implied, express or statutory, including but not limited to the warranties of
						non-infringement of third party rights, title, merchantability, satisfactory quality or fitness for a
						particular purpose, is given in conjunction with 12% Club Services or the services / products of the
						Partners or any data/content. While the Company may apply such technology as it deems fit to ensure the
						security of and prevent unauthorised access to its products/services, the Company does not warrant that
						products/services or any content/data will be provided uninterrupted or free from errors or that any
						identified defect will be immediately corrected. Further, no warranty is given that products/services or any
						data/content are free from any computer virus or other malicious, destructive or corrupting code, agent,
						program or macros. The Company provides any services and features on an “as is”, “where is”, “with all
						faults” basis.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You acknowledge that you have not solely relied on any representation/warranty made by the Company,
						Partners, service providers, subcontractors or agents and have made an independent assessment of the 12%
						Club Services. It is your responsibility to evaluate the accuracy, completeness and usefulness of all
						opinions, advice, services, merchandise and other information.
					</p>
					<p className="fs-18 font-medium mr-v-20">
						ALL OTHER WARRANTIES, WHETHER LEGAL, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY EXPRESS OR IMPLIED
						WARRANTIES OF MERCHANTABILITY, OF FITNESS FOR A PARTICULAR USAGE OR FOR THE INTENDED USE THEREOF ARE
						EXPRESSLY EXCLUDED.
					</p>
					<p className="fs-18 font-heavy mr-t-50">EXCLUSIONS OF LIABILITY</p>
					<p className="fs-18 font-light mr-v-20">
						Notwithstanding anything to the contrary the Company, its successors, agents, assigns, and each of their
						directors, officers, employees, associates, agents, and representatives shall in no event be liable to you
						or any other Person for:
					</p>
					<p className="fs-18 font-light mr-v-20">
						any indirect, incidental, special, consequential, punitive or economic loss, expense or damage arising from
						or in connection with any access, use or the inability to access or use products and services of the Company
						and the Partners or any other products/services and data/content or reliance on those, howsoever caused and
						regardless of the form of action (including tort or strict liability);
					</p>
					<p className="fs-18 font-light mr-v-20">
						any downtime costs, loss of revenue or business opportunities, loss of profit, loss of anticipated savings
						or business, loss of data, loss of goodwill or loss of value of any equipment including software; and/or
					</p>
					<p className="fs-18 font-light mr-v-20">
						any loss or damage arising as a result of a disclosure/sharing or hacking of the your login details and
						password, and any improper usage, failure or malfunction of any computer or mobile phone, unavailability of
						an electricity connection, other telecommunications equipment, any other services or equipment necessary to
						access products and services of the Company and the Partners.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Company shall not be liable for any inconvenience, loss, cost, damage or injury suffered by you or any
						third Person arising from or caused by:
					</p>
					<p className="fs-18 font-light mr-v-20">
						act or omission of any third party including but not limited to any equipment or software providers, any
						service providers, any network providers (including but not limited to telecommunications providers,
						internet browser providers and internet access providers), or any agent or subcontractor of any of the
						foregoing;
					</p>
					<p className="fs-18 font-light mr-v-20">the use of products and services of the Company or the Partners;</p>
					<p className="fs-18 font-light mr-v-20">
						theft or loss of your computer, mobile phone, data, login details and password, hardware and/or equipment;
					</p>
					<p className="fs-18 font-light mr-v-20">
						you are deprived of the use of the products and services of the Company or the Partners as a consequence of
						any act or omission by Company for any reason including but not limited to compliance with any applicable
						laws and/or regulations and any instructions and/or directions given by any local or foreign regulatory
						body, government agency, statutory board, ministry, departments or other government bodies and/or its
						officials.
					</p>
					<p className="fs-18 font-medium mr-v-20">
						NOTWITHSTANDING ANYTHING TO THE CONTRARY, IN NO EVENT, SHALL THE COMPANY OR ANY OF ITS DIRECTORS, EMPLOYEES,
						AGENTS AND/OR PERSONNEL, BE LIABLE TO THE USER OR ANY PERSON FOR ANY DAMAGES, LIABILITIES, LOSSES, AND
						CAUSES OF ACTION ARISING OUT OF OR RELATING TO: (I) THESE TERMS OF USE, THE PRODUCTS AND SERVICES, OR ANY
						REFERENCE SITE, APP, PRODUCTS OR SERVICES; OR (II) THE USER’S USE OR INABILITY TO USE THE PRODUCTS AND
						SERVICES OR ANY REFERENCE SITE; OR (III) ANY OTHER INTERACTIONS WITH THE COMPANY OR THE PARTNERS; HOWEVER
						CAUSED AND WHETHER ARISING IN CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY OR OTHERWISE, BEYOND OR IN
						EXCESS INR 1,000, OR ANY LOWER AMOUNT AS MAY BE PERMITTED UNDER APPLICABLE LAWS, WHICHEVER IS LESS.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Company shall make all best efforts to provide uninterrupted services subject to down time and regular
						maintenance Notwithstanding anything in these Terms of Use, you acknowledge that the access to the services,
						Website or Application may not be uninterrupted or error free or free from any virus or other malicious,
						destructive or corrupting code, program or macro.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Company is not responsible for any information or content uploaded by you. The Company is not
						responsible for any direct or indirect damages or losses caused to you, including without limitation, lost
						profits, business interruption or other loss resulting from use of or reliance in any way on anything
						available on the services provided by the Company. It is solely your responsibility to evaluate the
						accuracy, reliability, completeness and usefulness of information and content available on the Website and
						Application.
					</p>
					<p className="fs-18 font-heavy mr-t-50">THIRD PARTY SERVICES</p>
					<p className="fs-18 font-light mr-v-20">
						The Company may provide you with access to third-party tools and services and certain content, products and
						services available by the Company and the Partners may include materials from third-parties which the
						Company neither monitor nor have any control over.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You acknowledge and agrees that the Company provides access to such links, website, tools and services “as
						is” and “as available” without any warranties, representations or conditions of any kind and without any
						endorsement and the Company does not warrant and will not have any liability or responsibility for any
						third-party materials or websites, or for any materials, products, or services of third-parties, including
						the Partners. The Company shall have no liability whatsoever arising from or relating to your use of
						optional third-party tools and services. You are requested to review the third-party’s policies and
						practices carefully and make sure that you understand them. Complaints, concerns, or questions regarding
						third-party products should be directed to the third-party directly.
					</p>
					<p className="fs-18 font-heavy mr-t-50">INTELLECTUAL PROPERTY</p>
					<p className="fs-18 font-light mr-v-20">
						All Intellectual Property Rights in the 12% Club Services belong to the Company. Nothing in these Terms of
						Use shall operate to transfer any Intellectual Property Rights beyond the specific licenses, as may be
						specifically agreed in writing. The Company retains ownership of all right, title to, and interest in any
						and all Intellectual Property Rights developed, owned, licensed or created by the Company.
					</p>
					<p className="fs-18 font-light mr-v-20">
						No part or parts of the 12% Club Services may be reproduced, distributed, republished, displayed, broadcast,
						hyperlinked, transmitted, adapted, modified to create derivative works or otherwise commercially exploited
						in any manner or by any means or stored in an information retrieval system without the Company’s prior
						written permission.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You shall not claim any rights or interest in the Intellectual Property Rights of the Company, or in
						connection with any other services, features or product offered by the Company to you, including without
						limitation, the 12% Club Services. In no event shall you alter, tamper, vary, modify, change, disassemble,
						decompile, or reverse engineer any Intellectual Property Rights of the Company (including without limitation
						any Intellectual Property Rights licensed to the Company by any other Person) (collectively,{' '}
						<span className="font-medium">“IP Property”</span>), or permit others to do so. Altering, tampering,
						varying, modifying, changing, disassembling, decompiling and reverse engineering etc.
					</p>
					<p className="fs-18 font-heavy mr-t-50">INDEMNIFICATION</p>
					<p className="fs-18 font-light mr-v-20">
						You agree to indemnify, defend and hold harmless the Company, the Partners and their respective parent,
						subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers,
						subcontractors, suppliers, interns and employees (<span className="font-medium">“Indemnified Parties”</span>
						), from all claims, demands, losses, costs, fees suffered or incurred by the Indemnified Parties due to or
						arising out of the yout breach of these Terms of Use or the documents they incorporate by reference, or the
						your violation of any Applicable Law or the rights of a third-party.
					</p>
					<p className="fs-18 font-heavy mr-t-50">WAIVER</p>
					<p className="fs-18 font-light mr-v-20">
						If the Company does not exercise a right under these Terms of Use, shall not constitute a waiver of such
						right. Waiver of any right, remedy or breach of any subject matter contained in these Terms of Use shall not
						be viewed as a waiver by the Company, unless specifically agreed by Company in writing.
					</p>
					<p className="fs-18 font-heavy mr-t-50">FORCE MAJEURE </p>
					<p className="fs-18 font-light mr-v-20">
						For purposes of these Terms of Use, (<span className="font-medium">“Force Majeure Event”</span>) means any
						event or circumstance, regardless of whether it was foreseeable (including without limitation an act of war,
						hostility, or sabotage; act of God; electrical, internet or telecommunication outage that is not caused by
						the obligated party; government restrictions (including the denial or cancellation of any export or other
						license); criminal, revolutionary, or terrorist activity), that: (a) was not caused by a party and, (b)
						prevents the Company or the Partners from complying with any of its obligations pursuant to these Terms of
						Use. If a Force Majeure Event occurs, the Company will be excused from performing those obligations rendered
						un-performable by the Force Majeure Event. During a Force Majeure Event, the Company shall use reasonable
						efforts to limit damages and to resume its performance pursuant to these Terms of Use.
					</p>
					<p className="fs-18 font-heavy mr-t-50">ASSIGNMENT</p>
					<p className="fs-18 font-light mr-v-20">
						You shall not assign or transfer the rights and obligations under these Terms of Use. The Company may assign
						or transfer the rights and obligations contained in there Terms of Use to any Person.
					</p>
					<p className="fs-18 font-heavy mr-t-50">GOVERNING LAW AND JURISDICTION </p>
					<p className="fs-18 font-light mr-v-20">
						These Terms of Use will be governed by the laws of the India without any application of conflict of laws
						principles. In case of any differences, disputes or disagreements between you and the Company (
						<span className="font-medium">“Dispute”</span>), you and the Company shall attempt to amicable resolve such
						dispute within 30 days from the date on which such Dispute arose.
					</p>
					<p className="fs-18 font-light mr-v-20">
						In case you and the Company are unable to amicably resolve the Dispute within 30 days from the date on which
						such Dispute arose, the Dispute may, at your option or the option of the Company, be referred to arbitration
						by a sole arbitrator appointed jointly. The seat of arbitration shall be New Delhi and the language of
						arbitration shall be English.
					</p>
					<p className="fs-18 font-heavy mr-t-50">CONFIDENTIALITY</p>
					<p className="fs-18 font-light mr-v-20">
						In case you come into possession of any confidential information of Company or the Partners, you shall
						safeguard the same and shall not disclose such confidential information without the prior written consent of
						the Company.
					</p>
					<p className="fs-18 font-heavy mr-t-50">CONTACT INFORMATION</p>
					<p className="fs-18 font-light mr-v-20">
						‍Questions about these Terms of Use should be sent to Company to the following address below:
					</p>
					<p className="fs-18 font-light mr-v-5">Name: Mallika Janardhan</p>
					<p className="fs-18 font-light mr-v-5">
						Address: - 7th and 12th Floor, Building No. 8 Block-C, Cyber City DLF City Phase 2 Road, DLF City Phase 2, Gurugram, Gurugram, Haryana, 122008

					</p>
					<p className="fs-18 font-light mr-v-5">
						Email: support@twelve.club
						<p>[9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]</p>
					</p>
					<p className="fs-18 font-light mr-v-5">
						Contact Details: +91 9899355922
					</p>
					<p className="fs-18 font-light mr-v-20">
						Notice shall be sent to the contact address set forth here (as such may be changed by notice given to the other party), and shall be deemed delivered as of the date of actual receipt.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Any notice or demand to be given in relation to the Terms of Use shall be in writing and shall be deemed to
						have been duly given if sent by email to you at your email address, by message if sent to your registered
						mobile number, or by a courier service or registered A. D. or personally delivered to the address given to
						you. Any notice or demand so given or made shall be deemed to be given or made on the day it was so left or;
						as the case may be, two days following date on which it was so posted or transmitted, or in case of message,
						at the time of transmission to the registered mobile number, and shall be effectual notwithstanding that the
						same may be returned undelivered and notwithstanding change of your details.
					</p>
					{/* <p className="fs-18 font-light mr-v-20">
            You acknowledge that you may receive communication over voice calls
            and messages regarding the 12% Club Services, and products, and such
            other promotional communications. You unconditionally consent that
            such communications are upon your request and authorization, not
            'unsolicited commercial communications' as per the guidelines of
            Telecom Regulation Authority of India (TRAI) and are in compliance
            with the relevant guidelines of TRAI or such other Governmental
            Authority.
          </p> */}
					<p className="fs-18 font-light mr-v-20">
						You acknowledge that you may receive communication over voice calls and messages regarding the 12% Club
						Services, and products, and such other promotional communications. You consent that such communications are
						upon your request and authorization, not 'unsolicited commercial communications' as per the guidelines of
						Telecom Regulation Authority of India (TRAI) and are in compliance with the relevant guidelines of TRAI or
						such other Governmental Authority.
					</p>
					<p className="fs-18 font-heavy mr-t-50">CUMULATIVE RIGHTS</p>
					<p className="fs-18 font-light mr-v-20">
						The rights and remedies of the Company provided in this Terms of Use are not exclusive, but are cumulative
						upon all other rights and remedies to the full extent allowed by law
					</p>
					<p className="fs-18 font-heavy mr-t-50">ENTIRE UNDERSTANDING</p>
					<p className="fs-18 font-light mr-v-20">
						These Terms of Use constitute the entire agreement between Company and you pertaining to the subject matter
						hereof and supersedes in their entirety all written or oral agreements. Provided that, these Terms of Use
						shall be read in conjunction with any specific terms of use or conditions for the use of the 12% Club
						Services and any requirements specified by Partners. Any breach of such other conditions or terms shall be
						deemed to be a breach of these Terms of Use.
					</p>
					<p className="fs-18 font-heavy mr-t-50 text-center">SUPPLEMENTAL TERMS: DIGITAL GOLD</p>
					<p className="fs-18 font-light mr-v-20">
						By using the Platform and availing the 12% Club Services with respect to digital gold, you are agreeing to
						be bound by these terms and conditions, including all amendments made hereto from time to time. Further, if
						you do not agree to these Terms and the terms and conditions of safe gold (as available at{' '}
						<span
							className="underline font-medium"
							onClick={() => {
								window.open('https://www.safegold.com/terms-and-conditions', '_blank');
							}}
						>
							https://www.safegold.com/terms-and-conditions
						</span>
						), you shall not use the Platform for these services and should not proceed to create any accounts or
						acquire any subscriptions to the platform pertaining to the Gold services of the 12% Club Services.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You hereby agree and understand that all information and data collected and used by us shall be in
						accordance with privacy policy (available here).
					</p>
					<p className="fs-18 font-light mr-v-20">
						You understand and acknowledge that the Platform is an online platform that facilitates the you to purchase
						and sell gold in digital form from a brand named ‘Safe Gold’ (<span className="font-medium">“Gold”</span>)
						operated and managed by Digital Gold India Private Limited (“Safe Gold”), a company incorporated under the
						laws of India with corporate identification number U74999MH2017PTC293205, and Safe Gold provides for
						purchase and sale of Gold, and providing services of safe keeping, vaulting, delivery and fulfilment of gold
						and related services (“<span className="font-medium">Gold Service(s)</span>”).
					</p>
					<p className="fs-18 font-light mr-v-20">
						You expressly understand and agree that we are merely a facilitator that allows users to buy/sell gold
						through Safe Gold. You understand that by registering to the Platform and/or accessing Gold Services
						directly or indirectly from the Platform, you only receive the ability to use the Platform for purchase of
						gold from Safe Gold or such other products offered by us from time to time.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Before using the 12% Club Services/Gold Services, it is recommended that you read the terms of services and
						privacy policy of Safe Gold which can be accessed at https://www.safegold.com/terms-and-conditions. In case
						of any conflict between this Terms of Use and the terms of Safe Gold, the terms of Safe Gold in relation to
						the purchase and/or sale of Gold shall prevail.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You hereby agree that we shall not incur any liability in relation to any dispute relating that arises in
						relation to purchase of Gold or other Gold Services offered by Safe Gold. You expressly agree that we are
						not responsible for custody, quantity and quality of physical gold that you purchase through the Platform
						and hence we shall not be liable in case of any dispute that arises in relation to custody of physical gold
						with the custodian and trusteeship with the trustee administrator/trustee custodian and SafeGold.
					</p>
					<p className="fs-18 font-light mr-v-20">
						You acknowledge that Company is not responsible for the offered and sold Gold and that any disputes in
						relation such services shall be raised directly by You to Safe Gold.
					</p>
					<p className="fs-18 font-heavy mr-t-50">VERIFICATION AND REGISTRATION</p>
					<p className="fs-18 font-light mr-v-20">
						You acknowledge that the Company and/or Safe Gold may require certain information from you to verify you
						including but not limited to your mobile number, PAN Card etc and the same shall be furnished by you while
						registering on the Platform and/or opting for Gold Services. The registration will be validated by sending
						one time password (OTP) to registered mobile number. You further acknowledge that certain additional
						financial information, including your billing address, bank account details, credit card number, and/or
						other payment related details or other payment method data, and debit instructions or other standing
						instructions to process payments for Gold Services. The Company may also ask You to provide certain
						additional information as maybe necessary from time to time.
					</p>
					<p className="fs-18 font-heavy mr-t-50">MECHANSIM FOR PLACING THE ORDER AND CANCELLATION</p>
					<p className="fs-18 font-light mr-v-20">
						You can purchase Gold on the Platform on a pre-payment basis i.e., once you place the order and confirms the
						transaction for purchase of Gold and agree to the Terms of Use as well as the terms of Safe Gold (“Order”),
						the Platform will notify Safe Gold.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Once the payments are received by Safe Gold and the KYC information is found acceptable, Safe Gold shall
						issue an invoice to You confirming the Order placed within a period of 3 (three) business days of placing
						such order, in a manner it may deem fit.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Once the Order is placed, You are not entitled to cancel such a Order, provided however that Order shall
						stand cancelled if the payment fails for any reason whatsoever. The Platform and/or Safe Gold reserves the
						right to cancel the Customer Order, at its sole discretion, if Your information, provided prior to placing
						the Order, is not found acceptable and the Platform and/or Safe Gold are of the opinion that You are not
						eligible to purchase Gold from Safe Gold.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Notwithstanding anything to the contrary contained in these Terms of Use, the Company and/or Safe Gold shall
						be entitled to accept or reject the User, for any reason whatsoever, at its sole and exclusive discretion.
						In case of rejection of any of Your Customer Order, where payments have been received by the /Safe Gold,
						such payments shall be returned to You in accordance with these Terms and Conditions or the terms and
						conditions of Safe Gold, as applicable.
					</p>
					<p className="fs-18 font-heavy mr-t-50">GOLD PRICE AND PAYMENT MECHANISM</p>
					<p className="fs-18 font-light mr-v-20">
						The price displayed on the Platform, represents the market linked price of Gold as indicated by Safe Gold.
						Market linked prices means that these quotes are linked to the prices of gold in the commercial bullion
						market in India. However, it does not indicate that the gold prices displayed on the Platform will always be
						same as that of the prevalent prices displayed in other commercial bullion market. And it is your sole and
						absolute responsibility to compare the prices and take appropriate decisions after adequate diligence
					</p>
					<p className="fs-18 font-light mr-v-20">
						You understand that these prices may vary multiple times within a day, and accordingly Your payment
						obligations for any Order will depend on the prices displayed at the relevant time.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Payment policy: Payment will be accepted through the payment options made available on the Platform, which
						may include redirection to a payment gateways and aggregators.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The transactions on the Platform with will be completed only after successful transfer of money to Safe
						Gold’s bank account from Your registered bank account. Please note that the corresponding gold quantity will
						be credited to Your Account maintained with Safe Gold, within 5 (five) business days from the date of
						successful transfer of such amount. Please look at Safe Gold Terms and conditions accessed at{' '}
						<span
							className="underline font-medium"
							onClick={() => {
								window.open('https://www.safegold.com/terms-and-conditions', '_blank');
							}}
						>
							https://www.safegold.com/terms-and-conditions
						</span>{' '}
						and understand fully the terms of custodian and trusteeship arrangement before making the investment.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please note, once an order for purchase or sale has been confirmed by the customer, refunds or cancellation
						is not possible. All orders are final upon confirmation and completion.
					</p>
					<p className="fs-18 font-light mr-v-20">For any business related queries : shrishti.goyal@bharatpe.com</p>
					<p className="fs-18 font-light mr-v-20">For any support related queries : support@twelve.club</p>
				</div>
			</Screen>
		);
	}
}

export default Tnc;
