import lendenIcon from '../../../assets/website/lender/ldc.png';
import liquiLoansIcon from '../../../assets/website/lender/ll.png';

export const LenderDetails = [
	{
		image: liquiLoansIcon,
		email: 'grievances@liquiloans.com',
		name: 'NDX P2P Private Limited',
		phoneNo: '01142610483',
		websitelink: 'https://www.liquiloans.com/',
	},
	{
		image: lendenIcon,
		email: 'cs@lendenclub.com',
		phoneNo: '02248913091',
		name: 'Innofin Solutions Private Limited',
		websitelink: 'https://www.lendenclub.com/',
	},
];
