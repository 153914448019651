//React
import React from 'react';
//Components
import { Screen } from '../../components';
//Styles
import './styles.scss';
class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toCall: true,
			toUpdate: true,
		};
	}

	render() {
		const { state, props } = this;
		return <Screen screenClass="landing-screen">{/* Landing Page */}</Screen>;
	}
}

export default Landing;
