import React from 'react';
import cx from 'classnames';
import './styles.scss';
const FlexBox = ({
	flexBoxClass,
	children,
	jusitfyCenter,
	jusitfyAround,
	jusitfyBetween,
	jusitfyEvenly,
	justifyFlexEnd,
	alignCenter,
	flexColumn,
	w100,
	onClick,
	others,
}) => {
	const className = cx(
		'flex',
		jusitfyCenter && 'justify-center',
		jusitfyAround && 'justify-around',
		jusitfyBetween && 'justify-between',
		jusitfyEvenly && 'justify-evenly',
		justifyFlexEnd && 'justify-flex-end',
		alignCenter && 'align-center',
		flexColumn && 'flex-column',
		w100 && 'w-100',
		flexBoxClass,
	);
	return (
		<div className={className} onClick={onClick} {...others}>
			{children}
		</div>
	);
};
export default FlexBox;
