import zeroIcon from '../../../assets/website/card-icons/0.png';
import mobileIcon from '../../../assets/website/card-icons/mobile.png';
import shieldIcon from '../../../assets/website/card-icons/shield.png';
import thumbsUpIcon from '../../../assets/website/card-icons/thumbs-up.png';

export const HomeCards = [
	{
		icon: zeroIcon,
		text: 'Zero<br />joining fee',
	},
	{
		icon: mobileIcon,
		text: 'Seamless digital<br />convenience',
	},
	{
		icon: thumbsUpIcon,
		text: 'Trusted<br />by millions',
	},
	{
		icon: shieldIcon,
		text: 'Safe, easy<br />and transparent',
	},
];
