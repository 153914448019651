import './styles/index.scss';
import React from 'react';
import { Footer, Header } from './components';
import AppRouter from './routes';
import { isWebsiteLoadedInIframe, getURLParameter } from './utils';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			headerDarKBackground: false,
			isDarkTheme: false,
		};
	}

	componentDidMount(props) {
		const viewinapp = getURLParameter(window.location.href, 'viewinapp');
		const utm = getURLParameter(window.location.href, 'utm_campaign');
		const gclid = getURLParameter(window.location.href, 'gclid');

		if (viewinapp === '1') {
			const url = `https://twelveclub.onelink.me/2Cmd?pid=Google_web&af_dp=tpclub://dynamic?key=home${
				utm ? `&c=${utm}` : ''
			}&af_web_dp=http://twelve.club/${gclid ? `&${gclid}` : ''}`;

			window.location.href = url;
		}
	}

	renderLoader() {
		return (
			<div className="loader">
				<img src={require('./assets/website/loader.gif')} />
			</div>
		);
	}

	renderApp() {
		const { state, props } = this;
		return (
			<div className="app">
				<div className="router">
					{!isWebsiteLoadedInIframe && (
						<Header
							darkBackground={state['headerDarKBackground']}
							buttonText={state['isDarkTheme'] ? 'Light' : 'Dark'}
							buttonAction={() => {
								this.setState({
									isDarkTheme: !state['isDarkTheme'],
								});
							}}
							scrollAction={() => {
								window.scrollBy(0, (90 * window.innerHeight) / 100);
							}}
						/>
					)}
					<AppRouter />
					{!isWebsiteLoadedInIframe && <Footer />}
				</div>
				{/* {this.renderLoader()} */}
			</div>
		);
	}

	render() {
		const { state, props } = this;
		return this.renderApp();
	}
}

export default App;
