import React from 'react';
// LIBRARIES
import cx from 'classnames';
// STYLES
import './styles.scss';

const Button = ({ text, variant, disabled, buttonClassName, rounded, ...others }) => (
	<button
		type="button"
		variant={variant}
		disabled={disabled}
		className={cx('theme-btn clicking-animation', buttonClassName, rounded && 'rounded')}
		{...others}
	>
		{text}
	</button>
);

export default Button;
