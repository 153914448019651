import React from 'react';
import cx from 'classnames';
import './styles.scss';
import { isWebsiteLoadedInIframe } from '../../utils';
const Screen = ({ screenClass, children }) => {
	return (
		<div className={cx('main-screen animated fadeIn', screenClass, !isWebsiteLoadedInIframe && 'mr-t-80')}>
			{children}
		</div>
	);
};
export default Screen;
