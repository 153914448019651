//React
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
//Libraries
import cx from 'classnames';
//Components
import { FlexBox } from '..';
//Styles
import './styles.scss';
//Assets
import Button from '../Button';
import { history } from '../../utils';
import Logo from '../Logo';

import PlayStoreImg from '../../assets/website/play-store-header.png';
import AppStoreImg from '../../assets/website/app-store-header.png';
import BharatpeLogoImg from '../../assets/website/bharatpe-logo-dark.png';
import BharatpeLogoMobileImg from '../../assets/website/bharatpe-logo-dark-mobile.png';

const { width, height } = window.screen;
const Header = ({ headerClass, darkBackground, children, buttonText, buttonAction, scrollAction }) => {
	const [screenWidth, setScreenWidth] = useState(0);
	const url = window.location.href.split('/').splice(-1)[0];

	useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	if (url === 'help-support') {
		return null;
	}

	return (
		<div className={cx(darkBackground && 'dark-background', 'header animated fadeInDown')}>
			<FlexBox alignCenter flexBoxClass="actions h-100" jusitfyBetween>
				<FlexBox alignCenter flexBoxClass="w-auto">
					<img src={screenWidth > 768 ? BharatpeLogoImg : BharatpeLogoMobileImg} alt="bharatpelogo" className="logo" />
				</FlexBox>
				<Button
					text={'visit our new website'}
					onClick={() => {
						window.open('https://bharatpe.com/investbharatpe/', '_blank');
					}}
					buttonClassName="btn font-medium"
				/>
			</FlexBox>
		</div>
	);
};

export default Header;
