import Plan1Icon from '../../../assets/website/plans-card-icons/plan1.png';
import Plan2Icon from '../../../assets/website/plans-card-icons/plan2.png';
import Plan3Icon from '../../../assets/website/plans-card-icons/plan3.png';

export const Plans = [
	{
		interest: 10,
		lockin: 3,
		min: 10_000,
		icon: Plan1Icon,
	},
	{
		interest: 10.5,
		lockin: 6,
		min: 10_000,
		icon: Plan2Icon,
	},
	{
		interest: 11,
		lockin: 12,
		min: 10_000,
		icon: Plan3Icon,
	},
];
