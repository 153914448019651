import Step1 from '../../../assets/website/help-support/step1.png'
import Step2 from '../../../assets/website/help-support/step2.png'
import Step3 from '../../../assets/website/help-support/step3.png'
import Step4 from '../../../assets/website/help-support/step4.png'
import Step5 from '../../../assets/website/help-support/step5.png'
import Step6 from '../../../assets/website/help-support/step6.png'
import Step7 from '../../../assets/website/help-support/step7.png'

export const steps = [
    {
        title: 'Step 1',
        content: 'Login to 12% with your old phone number',
        image: Step1
    },
    {
        title: 'Step 2',
        content: 'On the home screen, click on "☰" at the top left corner',
        image: Step2
    },
    {
        title: 'Step 3',
        content: 'Go to "Profile"',
        image: Step3
    },
    {
        title: 'Step 4',
        content: 'Select "Delete my 12% Club account"',
        image: Step4
    },
    {
        title: 'Step 5',
        content: 'Click on "Delete account" to raise a request',
        image: Step5
    },
    {
        title: 'Step 6',
        content: 'Select "reason for deleting"',
        image: Step6
    },
    {
        title: 'Step 7',
        content: 'Enter OTP to confirm the request',
        image: Step7
    },
];