//React
import React from 'react';
//Components
import { Screen } from '../../components';
import { history } from '../../utils';
//Styles
import './styles.scss';
class PrivacyPolicy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toCall: true,
			toUpdate: true,
		};
	}

	render() {
		const { state, props } = this;
		return (
			<Screen screenClass="privacy-policy-screen animated zoomIn">
				<div className="content">
					<p className="fs-36 font-heavy">Privacy Policy</p>
					<p className="fs-18 font-light mr-v-20">
						<span className="font-medium">Privacy Policy“12% Club”</span> is the trademark of Resilient Innovations
						Private Limited, a company incorporated under the provisions of the Companies Act, 2013, having its
						registered office at 90/20 Malviya Nagar, New Delhi-110017, India (
						<span className="font-medium">“Company”</span>). The Company is engaged in the business of, inter alia,
						providing aggregator services to Users for receiving financial products and services from other partners.
					</p>
					<p className="fs-18 font-medium mr-v-20">
						Please note that by visiting twelve.club or accessing the mobile application titled “12%Club” you are
						accepting the practices described in this Privacy Policy. All users of the mobile application
						(“Application”)/ website (“Website”) are advised to read and understand our Privacy Policy carefully, as by
						accessing the Application you agree to be bound by the terms and conditions of the Privacy Policy and
						consent to the collection, storage and use of information relating to you as provided herein. By using the
						Application, you (“you”) expressly consent to 12% Club’s (“our” or “us”) use and disclosure of your personal
						information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to
						the Terms of Use which is available at
						<span
							className="underline font-medium"
							onClick={() => {
								window.open('https://twelve.club/#/terms-conditions', '_blank');
							}}
						>
							{' '}
							https://twelve.club/#/terms-conditions
						</span>
					</p>
					<p className="fs-18 font-light mr-v-20">
						Our Privacy Policy is subject to change from time to time without notice and it is strongly recommended that
						you periodically review our Privacy Policy as available on the Application/Website. In case you need any
						clarifications regarding this Privacy Policy, please do not hesitate to contact us at -
					</p>
					<p className="fs-18 font-light mr-v-5">Name: Rajeev Ranjan</p>
					<p className="fs-18 font-light mr-v-5">
						Address: - 3rd Floor, Ramnath House, 18, Community Centre, Opp. Jet Airways, Yusuf Sarai New Delhi South
						Delhi DL 110049 IN
					</p>
					<p className="fs-18 font-light mr-v-5">
						Email: support@twelve.club
						<p>[9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]</p>
					</p>
					<p className="fs-18 font-heavy mr-t-50">COLLECTION, STORAGE AND USE OF INFORMATION RELATED TO YOU</p>
					<p className="fs-18 font-light mr-v-20">
						We collect two basic types of information from you in conjunction with your use of the Website/ Application
						i.e. personal information, which is any information that exclusively identifies you (e.g., your name, email
						address, telephone number, postal address, credit card information amongst others) and non-personal
						information, which is information that does not exclusively identify you but is related to you nonetheless
						such as, information about your interests, demographics and use of the sites.
					</p>
					<p className="fs-18 font-light mr-v-20">
						We may automatically track certain information about you based upon your behaviour on the Application/
						Website. We use this information to do internal research on our users' demographics, interests, and
						behaviour to better understand, protect and serve our users. This information is compiled and analysed on an
						aggregated basis. This information may include the URL that you just came from (whether this URL is on our
						website or not), which URL you next go to (whether this URL is on our website or not), your computer browser
						information, your IP address, and other information associated with your interaction with the Application/
						Website.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please note that the Company collects, processes, stores and uses any information in compliance with Indian
						laws, including without limitation, the Information Technology Act, 2000. In case you are a resident or a
						citizen of a country other than the Republic of India and are, therefore, subject to additional and
						different requirements for data security, storage, processing or use (such as requirements under the General
						Data Protection Regulation (EU) 2016/679 or the California Privacy Rights Act of 2020), you may not use the
						services provided by the Company, the Application or the Website except with the explicit written permission
						of the Company to that effect.
					</p>
					<p className="fs-18 font-heavy mr-t-50">Personal Information</p>
					<p className="fs-18 font-light mr-v-20">
						We, on the Application/ Website, also collect and store personal information provided by you (
						<span className="font-medium">“Information”</span>), including but not limited to your
					</p>
					<p className="fs-18 font-light mr-v-20">
						:LocationCollect and monitor information about the location of your device to provide serviceability of your
						loan application, reduce risks associated with your application and also provide customised
						offers.ContactsCollect and monitor contacts and accounts information to detect references and accounts
						information to autofill data during processing your requests for value added services.Financial SMSCollect
						and monitor only transactional SMS (sent by 6-digit alphanumeric senders) about bank transactions, the names
						of the transacting parties, a description of the transactions etc. to help us perform a credit risk
						assessment. No personal SMS is stored and collected.CameraFor a better user experience in submitting
						documents.OthersAs may be explicitly required & requested from time to time. When you engage in certain
						activities on the Application
					</p>
					<p className="fs-18 font-light mr-v-20">
						Website, such as creating an account, ordering a product or service, posting a review, requesting
						information about our services or the services of our partners, we may ask you to provide certain
						information about yourself.When you use our Website or our Application, we collect and store your
						Information which is provided by you from time to time by{' '}
						<span className="font-medium">
							explicitly seeking permissions from YOU to get the required information.
						</span>{' '}
						Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience and
						services. This allows us to provide services and features that meets your needs, and to customize our
						Platform to make your experience safer and easier and to improve the services provided by us. More
						importantly, we collect Information from you that we consider necessary for achieving the aforementioned
						purpose.To the extent possible, we provide you the option of not divulging any specific Information that you
						wish for us not to collect, store or use. You may also choose not to use a particular service or feature of
						the Application/Website, and opt out of any non-essential communications from the Company. Depending on the
						activity, some of the Information we ask you to provide is identified as mandatory and some is identified as
						voluntary. If you do not provide the mandatory Information for a particular activity that requires it, you
						will not be permitted to engage in that activity.We only collect and use such Information from you that we
						consider necessary for achieving a seamless, efficient and safe experience, customized to your needs and use
						the Information to provide products to you, enhance the operation of the Application/ Website, improve our
						marketing and promotional efforts, analyse Application/ Website use, improve our product offerings, to
						personalise your experience and to provide you a better experience.We also collect other identifiable
						Information (your payment card details and transaction histories on the Application/Website or on third
						parties which are linked to the Application/Website) from you when you set up a free account with us as
						further detailed below. While you can browse some sections of our Application/Website without being a
						registered member as mentioned above, certain activities (such as availing of loans from the third party
						lenders on the Application/Website) requires registration and for you to provide the above details. The
						Application/Website shall clearly display the Information it is collecting from you, and you have the option
						to not provide such personal information. However, this will limit the services provided to you on the
						Platform.
					</p>
					<p className="fs-18 font-heavy mr-t-50">DISCLOSURE TO THIRD PARTIES. </p>
					<p className="fs-18 font-light mr-v-20">
						Further, we may share your Information with other corporate entities, affiliates and our partners to
						facilitate transactions and provide the services requested by you. Your Information may be shared/ sold by
						us or our affiliates with a third party business entity in case of any proposed merger, acquisition or
						re-organization, amalgamation, restructuring of business, including but not limited to Credit bureaus
						financial institutions, body corporates etc. Should such a transaction occur that other business entity (or
						the new combined entity) will be required to follow this Privacy Policy with respect to your Information.
					</p>
					<p className="fs-18 font-heavy mr-t-50">Non-personally identifiable information </p>
					<p className="fs-18 font-light mr-v-20">
						Non-personally identifiable information is Information that does not identify a specific end user. This type
						of information may include things like the URL of the website you visited before coming to our Website, the
						URL of the website you visit after leaving our Website, the type of browser you are using and your Internet
						Protocol address etc. Such Information maybe used by us or our partners to analyse trends and gather
						demographic information etc.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Where any service requested by you involves a third party, such Information as is reasonably necessary by
						the Company to carry out your service request may be shared with such third party. We may also engage with
						other companies/ persons to perform functions such as order fulfilment, delivering packages, analyzing data,
						providing marketing assistance, providing search results and links (including paid listings and links),
						processing credit card payments, and providing customer service. They have access to personal Information
						needed to perform their functions.
					</p>
					<p className="fs-18 font-light mr-v-20">
						We also do use your contact information to send you offers based on your interests and prior activity. The
						Company may also use contact information internally to direct its efforts for product improvement, to
						contact you as a survey respondent, to notify you if you win any contest; and to send you promotional
						materials from its contest sponsors or advertisers.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Further, upon registering on the Application/Website, you may from time to time choose to provide payment
						related financial information (credit card, debit card, bank account details, billing address etc.). We are
						committed to keeping all such sensitive data/information safe at all times and ensure that such
						data/information is only transacted over secure sites of approved payment gateways which are digitally
						encrypted, and provide the highest possible degree of care available under the technology presently in use.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Company will not use your financial information for any purpose other than to complete a transaction
						with you.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Application and Website has reasonable security measures, including as mentioned in Information
						Technology Act, 2000 in place to protect the loss or misuse of the information under our control. Whenever
						you change or access your account information, we offer the use of a secure server. However, transacting
						over the internet has inherent risks which can only be avoided by you following security practices yourself,
						such as not revealing account/login related information to any other person and informing our customer care
						team about any suspicious activity or where your account has/may have been compromised.
					</p>
					<p className="fs-18 font-light mr-v-20">
						If you send us personal correspondence, such as emails or letters, or if other users or third parties send
						us correspondence about your activities on the Application/Website, we may collect such information into a
						file specific to you. We do not retain any information collected for any longer than is reasonably
						considered necessary by us, or such period as may be required by applicable laws. The Company may be
						required to disclose any information that is lawfully sought from it by a judicial or other competent body
						pursuant to applicable laws.
					</p>
					<p className="fs-18 font-heavy mr-t-50">COLLECTION OF INSTALLED APPLICATIONS</p>
					<p className="fs-18 font-light mr-v-20">
						We collect a list of the installed applications’ metadata information which includes the application name,
						package name, installed time, updated time, version name and version code of each installed application on
						your device to assess your credit worthiness and enrich your profile with pre-approved customized loan
						offers.
					</p>
					<p className="fs-18 font-heavy mr-t-50">STORAGE</p>
					<p className="fs-18 font-light mr-v-20">
						We require the storage information permission that allows the App to enable you to upload photos and/or
						documents to complete the application form during your loan application journey.
					</p>
					<p className="fs-18 font-heavy mr-t-50">LINK TO OTHER SITES</p>
					<p className="fs-18 font-light mr-v-20">
						The Application/Website may contain links to third party websites that may collect personally identifiable
						information about you. We are not responsible for the privacy practices of such websites which we do not
						manage and control.
					</p>
					<p className="fs-18 font-heavy mr-t-50">LINK TO THIRD-PARTY SDK</p>
					<p className="fs-18 font-light mr-v-20">
						Our application has a link to a registered third party SDK which collects data on our behalf and data is
						stored to a secured server to perform a credit risk assessment. We ensure that our third party service
						provider takes extensive security measures in order to protect your personal information against loss,
						misuse or alteration of the data.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Our third-party service provider employs separation of environments and segregation of duties and have
						strict role-based access control on a documented, authorized, need-to- use basis. The stored data is
						protected and stored by application-level encryption. They enforce key management services to limit access
						to data.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Furthermore, our registered third party service provider provides hosting security – they use
						industry-leading anti-virus, anti-malware, intrusion prevention systems, intrusion detection systems, file
						integrity monitoring, and application control solutions.
					</p>
					<p className="fs-18 font-heavy mr-t-50">SECURITY PRECAUTIONS</p>
					<p className="fs-18 font-light mr-v-20">
						The Website/Application intends to protect your personal information and to maintain its accuracy as
						confirmed by you. We implement reasonable physical, administrative and technical safeguards to help us
						protect your personal information from unauthorized access, use and disclosure. For example, we encrypt all
						sensitive personal information when we transmit such information over the internet. We also require that our
						registered third party service providers protect such information from unauthorized access, use and
						disclosure.
					</p>
					<p className="fs-18 font-light mr-v-20">
						The Website/Application has stringent security measures in place to protect the loss, misuse and alteration
						of information under control. We endeavour to safeguard and ensure the security of the information provided
						by you. We use Secure Sockets Layers (SSL) based encryption, for the transmission of the information, which
						is currently the required level of encryption in India as per the law.
					</p>
					<p className="fs-18 font-light mr-v-20">
						We blend security at multiple steps within our products with the state of the art technology to ensure our
						systems maintain strong security measures and the overall data and privacy security design allow us to
						defend our systems ranging from low hanging issue up to sophisticated attacks.
					</p>
					<p className="fs-18 font-heavy mr-t-50">YOUR CONSENT</p>
					<p className="fs-18 font-light mr-v-20">
						By using the Website/Application and/ or by providing your information, you consent to the collection and
						use of the information you disclose on the Website in accordance with this Privacy Policy, including but not
						limited to Your consent for collecting, using, sharing and disclosing your information as per this privacy
						policy. You may withdraw your consent at any time effective from the date of such withdrawal. The withdrawal
						of consent will not affect the lawfulness of any data processed based on the consent provided by You before
						its withdrawal.
					</p>
					<p className="fs-18 font-light mr-v-20">
						If we decide to change our privacy policy, we will post those changes on this page so that you are always
						aware of what information we collect, how we use it, and under what circumstances we disclose it.
					</p>
					<p className="fs-18 font-heavy mr-t-50">
						CHOICES AVAILABLE REGARDING COLLECTION, USE AND DISTRIBUTION OF INFORMATION
					</p>
					<p className="fs-18 font-light mr-v-20">
						To protect against the loss, misuse and alteration of the information under its control, the Company has in
						place appropriate physical, electronic and managerial procedures. For example, the Company servers are
						accessible only to authorized personnel and your information is shared with employees and authorized
						personnel on a need to know basis to complete the transaction and to provide the services requested by you.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Although the Company endeavours to safeguard the confidentiality of your personally identifiable
						information, transmissions made by means of the Internet cannot be made absolutely secure. By using the
						Application/Website, you agree that the Company will have no liability for disclosure of your information
						due to errors in transmission and/or unauthorized acts of third parties.
					</p>
					<p className="fs-18 font-light mr-v-20">
						Please note that we will not ask you to share any sensitive data or information via email or telephone. If
						you receive any such request by email or telephone, please do not respond/divulge any sensitive data or
						information and forward the information relating to the same to legal@12club.in for necessary action.
					</p>
					<p className="fs-18 font-heavy mr-t-50">COMMUNICATION WITH COMPANY</p>
					<p className="fs-18 font-light mr-v-20">
						If you wish to correct or update any information you have provided, you may do so online, on the Application
						/Website itself. Alternatively, you may contact the Company to correct or update such information by sending
						an e-mail to: support@twelve.club
					</p>
					<p className="fs-18 font-light mr-v-20">
						In the event of loss of access to the Application/Website, you may contact the Company by sending an e-mail
						to: support@twelve.club
					</p>
					<p className="fs-18 font-light mr-v-20">
						In the event you wish to report a breach of the Privacy Policy, you may contact the designated Grievance
						Officer of the Company at:
					</p>
					<p className="fs-18 font-light mr-v-5">Name: Mallika Janardhan</p>
					<p className="fs-18 font-light mr-v-5">
						Address: - 7th and 12th Floor, Building No. 8 Block-C, Cyber City DLF City Phase 2 Road, DLF City Phase 2, Gurugram, Gurugram, Haryana, 122008
					</p>
					<p className="fs-18 font-light mr-v-5">
						Email: support@twelve.club
						<p>[9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]</p>
					</p>
					<p className="fs-18 font-light mr-v-5">
						Contact Details: +91 9899355922
					</p>
				</div>
			</Screen>
		);
	}
}

export default PrivacyPolicy;
