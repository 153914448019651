//React
import React from 'react';
//Components
import { Collapsible, Screen } from '../../components';
import { history } from '../../utils';
//Styles
import './styles.scss';
//Libraries
import Lottie from 'react-lottie';
import thinkingAnimationData from '../../assets/website/lottie/thinking.json';
//Assets
const thinkingDefaultOptions = {
	loop: true,
	autoplay: true,
	animationData: thinkingAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};
class Faq extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toCall: true,
			toUpdate: true,
			firstCollapsable: true,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				firstCollapsable: false,
			});
			setTimeout(() => {
				this.setState({
					firstCollapsable: true,
				});
			}, 500);
		}, 4000);
	}

	render() {
		const { state, props } = this;
		return (
			<Screen screenClass="faq-screen">
				<p className="fs-36 font-heavy">FAQ</p>
				<div className="faq-container animated fadeInLeft">
					<Collapsible componentHeader={'1. Details on 12% Club'} isCollapsed={state['firstCollapsable']}>
						<div className="w-80">
							<div className="qna">
								<p className="fs-18 font-medium">What is 12% Club?</p>
								<p className="fs-16 font-light mr-t-20">
									12% Club is a technology based application ("<span className="font-medium">12% Club</span>") that
									offers you the opportunity to earn a return of up to 12% on amounts you invest by deploying them with
									NBFC-peer to peer platforms ("<span className="font-medium">P2P NBFC Partners</span>"). Please note
									that 12% Club merely acts as a sourcing and technology partner to these regulated entities and does
									not guarantee return on your investment.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">Who are the NBFC partners and/or P2P NBFC Partner on the 12% Club?</p>
								<p className="fs-16 font-light mr-t-20">
									12% Club (Resilient Innovations Private Limited) partners with various P2P NBFC Partners and NBFC
									partners for providing these services. The investment amounts are placed with RBI regulated P2P NBFC,
									Innofin Solutions Private Limited ("<span className="font-medium">LenDenClub</span>") and NDX P2P
									Private Limited ("<span className="font-medium">Liquiloans</span>").
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">How to create an account on 12% Club?</p>
								<p className="fs-16 font-light mr-t-20">
									You can create your account by completing KYC on your 12% Club. Once your account is created, click on
									add money to start earning 12% returns on your investment (based on deployment to borrowers and
									repayments by borrowers through the P2P NBFC Partner) or click on borrow money to take loans at 12%
									interest subject to your creditworthiness as approved by our lending partner.
								</p>
							</div>
						</div>
					</Collapsible>
				</div>
				<div className="faq-container animated fadeInLeft delay-05s">
					<Collapsible componentHeader={'2. Investing with 12% Club'} isCollapsed={true}>
						<div className="w-80">
							<div className="qna">
								<p className="fs-18 font-medium">How to add money in 12% club account?</p>
								<p className="fs-16 font-light mr-t-20">
									You can add money using UPI, Debit Card or Net Banking. There are no charges to adding money to your
									12% Club Account.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">
									When is return earned on investment in 12% Club and how do I get a return up to 12% on my investment?
								</p>
								<p className="fs-16 font-light mr-t-20">
									The investment starts earning interest as soon as the money is deployed with the registered borrowers
									of P2P NBFC partner platform, The deployment usually takes upto 4 days. The return is on deployment
									with registered borrowers through the P2P NBFC Partner. The return is in form of interest paid by the
									borrowers to whom the money is lent through P2P NBFC Partners.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">How much money can I lend?</p>
								<p className="fs-16 font-light mr-t-20">
									Exposure of a single lender to the same borrower, across all P2Ps, shall not exceed Rs 50,000.
									Aggregate exposure of a lender to all borrower at any point of time, across all P2Ps, shall be subject
									to a cap of ₹ 10,00,000. However, you can lend up to ₹ 50,00,000 on submitting a certified net worth
									certificate of ₹ 50,00,000.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">What is the risk and how to mitigate it?</p>
								<p className="fs-16 font-light mr-t-20">
									Our P2P NBFC Partner tries to minimize risks by undertaking stringent verification and credit
									underwriting process. These investments which are in the form of lending through our P2P NBFC Partner
									are subject to risk on account of the borrower defaulting on the payment. In case of any default, we
									do regular follow-up and soft recovery with the borrower.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">Can a lender take a loan and lend at the same time?</p>
								<p className="fs-16 font-light mr-t-20">
									No, you can either take a loan or lend at any given point of time.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">What happens if any borrower fails to repay? Who bears the risk?</p>
								<p className="fs-16 font-light mr-t-20">
									Please note that we along with our P2P NBFC partner try to minimize risks by undertaking stringent
									verification and credit underwriting process. Further, peer to peer lending is unsecured and even
									after a rigorous process, we do not guarantee risk-free returns or no defaults. However, we have a
									soft recovery and collection process in place to ensure minimal defaults.
								</p>
								<p className="fs-16 font-light mr-t-20">
									When an individual borrower defaults, it can impact the pool of investors against whom the loan was
									made. For example, if your ₹ 3,000 (three thousand) is spread across 100 loans and the borrower
									defaults, the potential loss is ₹ 30. Multiple lenders invest in multiple borrowers.
								</p>
								<p className="fs-16 font-light mr-t-20">
									Essentially - for an ₹ 3,000 (three thousand) loan, there are multiple lenders who have invested in
									that ₹. 3000 loan and if that loan defaults, all the investors who have invested in that loan lose the
									built-in buffer.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">
									How is investing through the 12% club different from directly investing through your NBFC P2P Partner?
								</p>
								<p className="fs-16 font-light mr-t-20">
									We act as a sourcing agent and tech support for P2P NBFC Partners to provide our customers a unique
									experience on our 12% Club. Please note that our P2P NBFC Partners may have affiliations with third
									parties who independently offer different programmes and offers on their platform.
								</p>
							</div>
						</div>
					</Collapsible>
				</div>
				<div className="faq-container animated fadeInLeft delay-1s">
					<Collapsible componentHeader={'3. Money withdrawal from 12% Club'} isCollapsed={true}>
						<div className="w-80">
							<div className="qna">
								<p className="fs-18 font-medium">Are there any withdrawal charges?</p>
								<p className="fs-16 font-light mr-t-20">No! There are no withdrawal charges.</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">
									Can you withdraw your invested amount before the loan matures? Can the invested amount be withdrawn
									partially?
								</p>
								<p className="fs-16 font-light mr-t-20">
									The P2P NBFC Platform partners have an active secondary market and the lender can avail liquidity
									through the secondary market feature of the platforms. However, having a secondary market does not
									ensure that you will get the liquidity through it.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">
									What are the parameters for dealing with a withdrawal when there are various lenders wanting to
									withdraw the invested money at the same time that has already been disbursed?
								</p>
								<p className="fs-16 font-light mr-t-20">
									Our P2P NBFC Partners have a secondary market and there is no guarantee of liquidation by the 12%
									Club. If there is a run on the platform the worst that can happen is the money will come in a
									staggered way depending on the underlying tenure of the loan (which is average tenure of 6 months). A
									run on the platform does not mean the underlying loans are bad, it just means the lender has to wait
									longer to get their money back.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">
									How long does it take for the withdrawal amount to be settled to my bank account?
								</p>
								<p className="fs-16 font-light mr-t-20">
									Subject to the transactions getting fulfilled on secondary market of the platform, withdrawals
									requests are generally processed within 1 business day from the date of request of withdrawal.
									However, in rare cases, there might be a delay at any of the intermediary bank's end. If you are
									facing any issue beyond the aforementioned time, please feel free to chat with us or contact our
									customer support team.
								</p>
							</div>
							<div className="qna mr-t-50">
								<p className="fs-18 font-medium">Where will money withdrawn from the 12% Club get credited?</p>
								<p className="fs-16 font-light mr-t-20">
									Withdrawn money will be credited to your registered bank account.
								</p>
							</div>
						</div>
					</Collapsible>
				</div>
				<div className="faq-container animated fadeInLeft delay-15s">
					<Collapsible componentHeader={'4. Grievance Redressal'} isCollapsed={true}>
						<div className="w-80">
							<div className="qna">
								<p className="fs-16 font-light mr-t-20">
									In case you wish to reach out to our NBFC P2P Partner regarding your investment, please contact:
								</p>
								<ul className="ul-style-decimal p-l-15">
									<li className="mr-t-15 fs-16 font-light">
										<p>
											Name: <span className="font-medium">NDX P2P Private Limited (Liquiloans)</span>
										</p>
										<p className="mr-t-15">Grievance redressal link:</p>
										<p className="mr-t-15">
											<a
												href="https://www.liquiloans.com/customer-grievances-redressal-mechanism"
												target="_blank"
												className="font-medium"
											>
												https://www.liquiloans.com/customer-grievances-redressal-mechanism
											</a>
										</p>
										<p className="mr-t-15">
											Email:{' '}
											<a href="mailto:customercare@liquiloans.com" target="_blank" className="font-medium">
												customercare@liquiloans.com
											</a>{' '}
											and/or{' '}
											<a href="mailto:info@liquiloans.com" target="_blank" className="font-medium">
												info@liquiloans.com
											</a>
										</p>
									</li>
									<li className="mr-t-15 fs-16 font-light">
										<p>
											Name: <span className="font-medium">Innofin Solutions Private Limited (LenDenClub)</span>
										</p>
										<p className="mr-t-15">Grievance redressal link:</p>
										<p className="mr-t-15">
											<a
												href="https://www.lendenclub.com/grievance-redressal-mechanism/"
												target="_blank"
												className="font-medium"
											>
												https://www.lendenclub.com/grievance-redressal-mechanism/
											</a>
										</p>
										<p className="mr-t-15">
											Email:{' '}
											<a href="mailto:cs@lendenclub.com" target="_blank" className="font-medium">
												cs@lendenclub.com
											</a>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</Collapsible>
				</div>
				<div className="faq-container animated fadeInLeft delay-2s">
					<Collapsible componentHeader={'5. Steps to close account'} isCollapsed={true}>
						<div className="w-80">
							<div className="qna">
								<p className="fs-16 font-light mr-t-20">
									To close/delete the 12% club account, follow the steps mentioned <a onClick={() => {
										window.open('/#/help-support', '_blank');
									}} className='faq-help-support'>here</a>.
								</p>
							</div>
						</div>
					</Collapsible>
				</div>
				<div className=" animated fadeInUp delay-25s">
					<Lottie options={thinkingDefaultOptions} />
				</div>
			</Screen>
		);
	}
}

export default Faq;
