import React from 'react';
import FlexBox from '../../FlexBox';
import PlayStoreImg from '../../../assets/website/play-store.png';
import AppStoreImg from '../../../assets/website/app-store.png';
import IntroPhoneImg from '../../../assets/website/intro-mobile.png';
import InvestBharatpeIntro1 from '../../../assets/website/invest-bharatpe-intro-1.png';
import InvestBharatpeIntro2 from '../../../assets/website/invest-bharatpe-intro-2.png';
import './styles.scss';

const IntroNew = () => {
	return (
		<div className="intro-page-new pos-rel ovr-hdn section-height">
			<FlexBox flexColumn jusitfyCenter alignCenter flexBoxClass="pos-rel animated zoomIn w-100 h-100 z-5">
				<FlexBox flexBoxClass="w-100 flex-md-col" jusitfyCenter alignCenter>
					<FlexBox alignCenter flexColumn jusitfyCenter flexBoxClass="pos-rel h-100 intro-section gap-4">
						<img src={InvestBharatpeIntro1} alt="invest bharatpe" className='invest-bharatpe' />
						<FlexBox jusitfyCenter flexColumn alignCenter flexBoxClass="">
							<p className='font-heavy clr-primary'>Download the App</p>
							<FlexBox jusitfyCenter flexBoxClass="download-app">
								<img
									src={PlayStoreImg}
									className="app-download pointer"
									alt="play-store"
									onClick={() => {
										window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
									}}
								/>
								<img
									src={AppStoreImg}
									className="app-download pointer"
									alt="app-store"
									onClick={() => {
										window.open('https://twelveclub.onelink.me/2Cmd/website', '_blank');
									}}
								/>
							</FlexBox>
						</FlexBox>
					</FlexBox>
					<FlexBox jusitfyCenter alignCenter flexBoxClass="pos-rel h-100 intro-section md-justify-center">
						<img alt="invest and earn" src={InvestBharatpeIntro2} className="intro-phone h-100" />
					</FlexBox>
				</FlexBox>
			</FlexBox>
		</div>
	);
};

export default IntroNew;
