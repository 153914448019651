import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './styles/animate.css';
import './styles/variables.scss';
import './styles/common.scss';
import './styles/classes.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: 'GTM-MZZRRP4',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorkerRegistration.unregister();
