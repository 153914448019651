import React from 'react'

import './styles.scss'
import { steps } from './Content.constants';

const Content = () => {
  return (
    <div className='help-support-content'>
        <div className='mb-12'>
            <h2>Steps to close your account</h2>
            <p className='bold'>Considering Closing Your 12% Club Account?</p>
            <p>At 12% Club, our intention is to serve you with the best. We'd be sad to see you go!</p>
            <p>However, we are here to help you out with a smooth closure:</p>
            <ul>
                <li>
                    <span>Investments:</span>
                    <p className='mt-neg-10'>Withdraw your entire investment balance before closing your account.</p>
                </li>
                <li>
                    <span>Active Withdrawals:</span>
                    <p className='mt-neg-10'>Ensure all pending withdrawals are settled before closing your account.</p>
                </li>
            </ul>
        </div>

        <div className='mb-12'>
            <p className='bold'>Ready to Close?</p>
            <p>Once you've completed these steps, use the 12% Club app to raise account deletion requests.</p>
        </div>
        <div className='mb-12'>
            <h2>Before you close your account, here are some things you should know:</h2>
            <ul>
                <li>
                    <span>Closing your account unlinks your phone number and the associated login ID and password are no longer valid. Effectively, you cannot login to 12% club anymore with these tokens</span>
                </li>
            </ul>
            <p>To comply with legal rules that apply to us about keeping records or information in which case we will retain your data for a minimum of 5 (five) years after your account has been terminated or longer depending on applicable laws. Please find the link for Privacy policy <a href="https://twelve.club/#/privacy-policy" target='_blank' className='bold'>here</a>.</p>
        </div>
        <div className='mb-12'>
            <h2>To raise account deletion request, follow the below-mentioned steps:</h2>
            <div className="grid">{steps.map(step => (<div key={step.title}>
                <p>{step.title}: {step.content}</p>
                <img src={step.image} alt={step.title} className='phone-img' />
            </div>))}</div>
        </div>
        <h2 className='center'>We will verify the request and close your account soon.</h2>
    </div>
  )
}

export default Content;
