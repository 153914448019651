//React
import React from 'react';
// NAVIGATION
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { history } from './utils';
// CONTAINERS
//General
import { Landing, Home2, Tnc, PrivacyPolicy, Faq, HelpSupport } from './containers';
const AppRouter = ({ loader }) => (
	<Router history={history}>
		<Switch>
			<Route exact path="/" component={Home2} />
			<Route path="/terms-conditions" component={Tnc} />
			<Route path="/privacy-policy" component={PrivacyPolicy} />
			<Route path="/faq" component={Faq} />
			<Route path="/help-support" component={HelpSupport} />
		</Switch>
	</Router>
);
export default AppRouter;
