import React from 'react';
import { HelpSupportHeader, HelpSupportContent } from '../../components/HelpSupport';

const HelpSupport = () => {
	return <div>
		<HelpSupportHeader />
		<HelpSupportContent />
	</div>;
};

export default HelpSupport;
