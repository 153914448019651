import React from 'react';
import './styles.scss';

const SeparatorComponent = ({ caption, className, onClick }) => {
	return (
		<div className={['separator', className].join(' ')} onClick={onClick}>
			<div className="line"></div>
			<p className="name fs-20 font-heavy">{caption}</p>
		</div>
	);
};

export default SeparatorComponent;
