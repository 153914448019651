export const getURLParameter = (qrString, paramName) => {
	const qr = qrString.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
	const results = regex.exec(qr);

	return results && results.length > 0 ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
};

const currencyFormatter = Intl.NumberFormat('en-IN', {});

export const currencyFormat = (amount) => currencyFormatter.format(amount);
