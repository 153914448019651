import React from 'react'

import HeaderBgImg from '../../../assets/website/help-support/header-bg.png'
import HeaderUserImg from '../../../assets/website/help-support/header-user.png'

import './styles.scss'

const Header = () => {
	const [screenWidth, setScreenWidth] = React.useState(0);

	React.useLayoutEffect(() => {
		const updateSize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

  return (
    <div className='help-support-header'>
      <img src={HeaderBgImg} className='bg' />
      <span>Help &amp; Support</span>
      {screenWidth > 768 && <img src={HeaderUserImg} className='user' />}
    </div>
  )
}

export default Header