import React from 'react';
import DownArrowLogo from '../../assets/website/down-arrow.svg';
import cx from 'classnames';
import { Separator } from '..';
import './styles.scss';
class Collapsible extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toCollapse: false,
		};
	}

	collapseActions(toCollapse) {
		let newState = { ...this.state };
		newState['toCollapse'] = toCollapse;
		this.setState(newState);
	}

	componentDidMount() {
		this.collapseActions(this.props.isCollapsed);
	}

	componentDidUpdate(prevProps) {
		if (this.props.isCollapsed !== prevProps.isCollapsed) {
			this.collapseActions(this.props.isCollapsed);
		}
	}

	render() {
		const { state, props } = this;
		let { children, maxHeight, componentHeader } = props;
		return (
			<div className={cx(!state['toCollapse'] && 'collapsed', 'collapsible-section')}>
				<Separator caption={componentHeader} onClick={() => this.collapseActions(!state['toCollapse'])} />
				<img
					className="navigation-arrow"
					onClick={() => this.collapseActions(!state['toCollapse'])}
					src={DownArrowLogo}
					style={state['toCollapse'] ? { transform: 'rotate(-90deg)' } : { transform: 'rotate(0deg)' }}
				/>
				{children}
			</div>
		);
	}
}

export default Collapsible;
